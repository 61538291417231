export const environment = {
  Wp: false,
  IDTecnico: "RT",
  IDCliente: "C",
  IDAdmin: "AZ",
  IDRoot: "SA",
  ClientiType: ['CA', 'C', 'AC'],
  filtrife: [],
  legenda:[
    {area: "RMA.rma", legenda: [
        {label: "RMA.legenda.stato1", color: "#E95A3B"},
        {label: "RMA.legenda.stato2", color: "#50270A"},
        {label: "RMA.legenda.stato3", color: "#758fef"},
        {label: "RMA.legenda.stato4", color: "#F6BE30"},
        {label: "RMA.legenda.stato5", color: "#6DA447"}
      ]
    }],
  production: true,
  apiUrl: 'https://acquisti.klimaitalia.it/api',
  bbbUrl: 'https://newsbs.ddns.net/bigbluebutton',
  langPrefix: './assets/i18n/',
  langSuffix: '.json',
  ClientebaseUrl: '/Cliente/formazienda/c/',
  baseUrl: '/acquisti/acquisti',
  href: '/Login',
  homepages: [
    {Ruoli: ["AC"], link: "/Cliente/profile"}
  ],
  //#region Layout Config
  logoPath: 'assets/images/KlimaNuovo.png',
  loginMsg: '',
  loginbgPath: 'assets/images/klimasede.jpeg',
  icon:'assets/images/KlimaIMGxicon.png',
  logoBg: 'skin1', // six possible values: skin(1/2/3/4/5/6),
  navbarbg: 'skin2', // six possible values: skin(1/2/3/4/5/6)
  sidebarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
  userCircleColor: "",
  theme: 'light',
  dir: 'ltr', // two possible values: ltr, rtl
  layout: 'vertical', // fixed value. shouldn't be changed.
  sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
  sidebarpos: 'fixed', // two possible values: fixed, absolute
  headerpos: 'fixed', // two possible values: fixed, absolute
  boxed: 'full', // two possible values: full, boxed
  //#endregion
  //#region Moduli
  Modules: {
    "twoFactorAuth": false,
    "Admin": true,
    "Personale": true,
    "Clienti": true,
    "Interventi": false,
    "Prima_Nota": false,
    "Listini": true,
    "Progetti": true,
    "Prodotti": true,
    "Documenti": false,
    "Scadenzario": false,
    "Spedizioni": false,
    "Stampe": true,
    "Task": false,
    "Shared": false,
    "Dashboard": false,
    "Settings": true,
    "Formazione": false,
    "VersioneCarbotti": false,
    "PartiProdotto": false,
    "Parti": false,
    "Rma": false,
    "Ecommerce": false,
    "ScadenzeFiglie": false,
    "Registrazione": false,
    "Ticket": true,
    "Acquisti": true,
    "taskboard": false,
    "Camera":false,
    "Valutazioni":false
  },
//#endregion
  //#region Addon
  Personale: {
    Corsi: true,
  },
  PartiAddOn: [false],//  categorie associate
  ClientiAddOn: {
    enable:true,
    info:false,
    commerciale: false,
    segnalatore: false,
    segnalatoreInterno: false,
    sedi: false,
    TipoSedePrimaria: false,
    attivita: false,
    geolocalizzazione: false,
    ruolo: true,
    Modifica: false,
    prodotti: false,
    rma: false,
    codAdhoc:true,
    accesso: false,
    referenti: true,
    interventi: false,
    note_private: false,// rende invisibile il campo note al cliente
    rating: false,
    ruoliAccesso:false,
    compilazione:false,
    luogoNascita: false,
    dataNascita: false,
    statoCivile:false,
    titoloStudio: false,
    occupazione: false,
    invalidita: false,
    legge104: false,
    diagnosi: false,
    accompagnamento:false,
    pensione: false,
    tutelare: false,
    medico: false,
    telMedico: false,
    terapia:false,
    checkAmm: false,
  },
  ProdottiAddOn: {
    shortDescription: true,
    classeEnergetica: true,
    tags: true,
    video: true,
    dettaglio:true,
    files: true,
    cart: true,
    codice: true,
    codice_produttore: true,
    tipo: true,
    standby: true,
    tipi:true, // tipi di prodotto (1 - 2ante klima)
    cat_image: true,
    lista_prezzo: false,
    TranslatedDesc: false,
    codAdhoc:true,
    DragSort: false,
    cat_Sort: false,
    sottoTitolo: false,
    rifornimento:true,
    addProdotti:true,
    voceCostoSconto:{
      idProdotto: 1454,
      label: "Sconto",
      valNegativo: true,
    },
    prodottiCorrelati: false,

  },
  CalendarAddon: {
    managerScadenza : false,
  },
  TaskAddon: {
    checkList : false,
  },
  ProgettiAddon: {
    commerciale: false,
    segnalatore: false,
    categoriaVoceDiCosto: false,
    lavorato: false,
    OggettoNascosto: true,
    HideColore: true,
    HidePrezzo: true,
    CampiOfferta: [
      {
        modificabile: true,
        nome: 'Offerta.Responsabile',
        oggetti: ['manager'],
        inputType: 'singleDropdown',
        source: 'managers',
        internalOutput: 'managerObject',
        OnFilter: 'filtroManager',
        OnChange: 'OnManagerChange',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'fullName',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'cliente.commerciale',
        oggetti: ['commerciale'],
        inputType: 'singleDropdown',
        source: 'managers',
        internalOutput: 'commerciale',
        OnFilter: 'filtroCommerciale',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'fullName',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'cliente.segnalatore',
        oggetti: ['clienti'],
        inputType: 'singleDropdown',
        source: 'clienti',
        internalOutput: 'segnalatore',
        OnFilter: 'filtrosegnalatore',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'nominativo',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
          noDataAvailablePlaceholderText: "Nessun Cliente trovato ",
          moreButton: true,
          moreButtonText: "Cerca in AdHoc",
        },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      // {
      //   modificabile: true,
      //   nome: 'Colore',
      //   oggetti: ['colore'],
      //   inputType: 'color',
      //   titleClass: 'control-label col-form-label',
      //   class: 'col-lg-1',
      //   visibleifProhibited: true
      // },
      // {
      //   modificabile: true,
      //   nome: 'Lavorato',
      //   oggetti: ['lavorato'],
      //   inputType: 'text',
      //   titleClass: 'control-label col-form-label',
      //   class: 'col-lg-1',
      //   visibleifProhibited: true
      // },
      // {
      //   modificabile: true,
      //   nome: 'Oggetto:',
      //   oggetti: ['oggetto'],
      //   inputType: 'text',
      //   titleClass: 'control-label col-form-label',
      //   class: 'col-lg-3',
      //   visibleifProhibited: true
      // },
      {
        modificabile: true,
        nome: 'Numero Ordine:',
        oggetti: ['codice'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-2',
        placeholder: 'Numero Ordine:',
        visibleifProhibited: true
      },
      {
        prevDivClass: "col-md-12",
        modificabile: true,
        nome: 'Offerta.Creazione',
        oggetti: ['data_creazione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Offerta',
        oggetti: ['data_emissione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Accettazione',
        oggetti: ['data_accettazione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Conferma',
        oggetti: ['data_conferma'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Note',
        oggetti: ['note'],
        inputType: 'textarea',
        titleClass: 'control-label col-form-label',
        class: 'col-md-6',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Note Private',
        oggetti: ['note_private'],
        inputType: 'textarea',
        titleClass: 'control-label col-form-label',
        class: 'col-md-6',
        visibleifProhibited: false
      },
      //   {
      //   modificabile:true,
      //   nome: 'Gestore',
      //   oggetti: ['manager'],
      //   inputType: 'singleDropdown',
      //   source: 'managers',
      //   internalOutput: 'gestoreObject',
      //   OnFilter: 'filtroCommerciale',
      //   placeholder: 'Seleziona',
      //   settings: {
      //     singleSelection: true,
      //     closeDropDownOnSelection: true,
      //     singleOutput:true,
      //     idField: 'id',
      //     textField: 'fullName',
      //     searchPlaceholderText: "inserire almeno 3 caratteri",
      //     allowSearchFilter: true,
      //     allowRemoteDataSearch: true,
      //   },
      //   titleClass: 'control-label col-form-label'  ,
      //   class: 'col-lg-3',
      //   visibleifProhibited: true
      //
      // },
    ],
    CampiSottoOfferta: []
  },
  RmaAddOn: {
    taskGenerico: false,
    vociDiCosto: true,
    provaAcquisto:true,
    CategoriaProdotto: true,
    TipoAcquisto: true,
    TipoAcquistoSource: "ClientiType",
    Rivenditore:true,
    NumeroDocumento: false,
    DataDocumento: true,
    Ricevuta: true,
    Targhetta: true,
    CircostanzaDifetto: true,
    DescrizioneDifetto: false,
    Difetto: true,
    Telefono: false,
    Garanzia: false,
    EstensioneGaranzia: false,
    Allegati: true,
    Modello: true,
    Matricola: true,
    Brand: true,
    sottoTask: true,
    chiuso:true,
    dapertura_c: true,
    diniziop: false,
    dfinep: false,
    dinizioe: true,
    dfinee: true,
    Ritiro: {
      Enabled: true,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true
    },
    Cliente: {
      Enabled: true,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true
    },
    Assistenza: {
      Enabled: true,
      Referente: false,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true,
      Tecnico: false,
    }
  },
  //#endregion
  //#region Menu
  Menu: [
    {
      title: "Menu",
      class: "has-arrow",
      label: "Menu",
      extralink: true
    },
    {
      module: "Dashboard",
      path: "/dashboard/dashboard",
      title: 'menu.dashboard',
      icon: "mdi mdi-gauge",
      capacita: ["menu-dashboard"]
    },
    {
      module: "Personale",
      path: "/listautenti/personale",
      title:'menu.PersonaleLabel',
      icon: "far fa-user",
      capacita: ["menu-persone"]
    },
    /*    {
          module: "Clienti",
          path: "/listautenti/aziende",
          title:'menu.clienti',
          icon: "fas fa-address-book",
          capacita: ["menu-clienti"]
        },*/
    {
      module: "Clienti",
      path: "/listautenti/aziende/F",
      title:'Fornitori',
      icon: "fas fa-address-book",
      capacita: ["menu-clienti"]
    },
    {
      module:"Progetti",
      path: "progetti/progetti",
      title: 'menu.progetti',
      icon: "mdi mdi-calendar-multiple-check",
      capacita: ["menu-progetti"]
    },
    {
      module: "Task",
      path: "task/calendario",
      title: 'menu.calendario',
      icon: "mdi mdi-calendar",
    },
    {
      module: "Task",
      path: "task/list",
      title: 'menu.lista',
      icon: "mdi mdi-calendar"
    },
    {
      module: "Listini",
      path: "listino/listini",
      title: 'menu.listini',
      icon: "fas fa-bars",
      capacita: ["gestione-listino"],
    },
    {
      module: "Acquisti",
      path: "acquisti/acquisti",
      title: 'menu.acquisti',
      icon: "fas fa-cart-plus",
      capacita: ["menu-acquisti"],
    },
    {
      module: "Acquisti",
      path: "acquisti/groupedlavorazioni",
      title: 'menu.groupedlavorazioni',
      icon: "fab fa-opencart",
      capacita: ["menu-acquisti"],
    },
    {
      module: "Acquisti",
      path: "acquisti/lavorazioni",
      title: 'menu.lavorazioni',
      icon: "fab fa-opencart",
      capacita: ["menu-acquisti"],
    },
    {
      module: "Acquisti",
      path: "acquisti/spedizioni",
      title: 'menu.spedizioni',
      icon: "far fa-paper-plane",
      capacita: ["menu-spedizioni"],
    },
    {
      module: "Acquisti",
      path: "acquisti/container",
      title: 'menu.container',
      icon: "fas fa-truck-loading",
      capacita: ["menu-container"],
    },
    {
      module: "Acquisti",
      path: "acquisti/container/voci",
      title: 'menu.vocicontainer',
      icon: "fas fa-truck-moving",
      capacita: ["menu-container"],
    },
    {
      module: "Acquisti",
      path: "acquisti/scorte",
      title: 'menu.scorte',
      icon: "fas fa-calculator",
      capacita: ["menu-scorte"],
    },
    {
      module: "Interventi",
      title: "Attività",
      icon: "mdi mdi-wrench",
      class: "has-arrow",
      capacita: ["menu-fasi"],
      submenu: [
        {
          path: "interventi/interventi",
          title: 'menu.interventi',
          icon: "mdi mdi-wrench",
        },
        {
          path: "interventi/gantt",
          title: "Gantt",
          icon: "mdi mdi-chart-areaspline",
        }
      ]
    },
    {
      module: "Documenti",
      path: "progetti/documenti",
      title: 'menu.DocumentiLabel',
      icon: "mdi mdi-file-document",
      capacita: ["menu-documenti"]
    },
    {
      module: "Prodotti",
      path: "progetti/prodotti",
      title: 'menu.ProdottiLabel',
      icon: "mdi mdi-barcode-scan",
      capacita: ["menu-prodotti"]
    },
    {
      module: "Formazione",
      title: 'menu.FormazioneLabel',
      icon: "mdi mdi-school",//mdi-calendar-multiple-check //mdi-engine-outline
      class: "has-arrow",
      capacita: ["menu-servizi", "menu-formazione", "menu-calendar-servizi","menu-utenti-servizi"],
      submenu: [
        {
          path: "formazione/media",
          title: "menu.corsiMultimediali",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-formazione"]
        },
        {
          path: "formazione/corsi",
          title: "menu.corsi",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-calendar-servizi", "menu-servizi"]
        },
        {
          path: "/listautenti/personale/mod",
          title: "menu.docenti",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "/listautenti/personale/tut",
          title: "menu.tutor",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "/listautenti/personale/stu",
          title: "menu.studenti",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "formazione/materie",
          title: "menu.materie",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu_calendar_tirocini"]
        },
        {
          path: "formazione/sedi",
          title: "menu.sedi",
          icon: "mdi mdi-currency-eur",
        }
      ]
    },
    {
      module: "Prima_Nota",
      title: "menu.Prima_NotaLabel",
      icon: "mdi mdi-cart",//mdi-calendar-multiple-check //mdi-engine-outline
      class: "has-arrow",
      capacita: ["menu-spese","menu-fatture"],
      submenu: [
        {
          path: "primanota/fatture",
          title: "menu.fattVendita",
          capacita: ["menu-fatture"]
        },
        {
          path: "/listautenti/aziende/f",
          title: "menu.fornitori",
          capacita: ["menu-fatture"]
        },
        {
          path: "primanota/primanota",
          title: "menu.primanota",
          capacita: ["menu-spese"]
        },
        {
          path: "primanota/lista-spese",
          title: "menu.listaspese",
          capacita: ["menu-spese"]
        }
      ]
    },
    {
      module: "Parti",
      path: "progetti/parti",
      title: "menu.PartiLabel",
      icon: "mdi mdi-barcode",
      capacita: ["menu-parti"]
    },
    {
      module: "Parti",
      path: "progetti/parti/categorie-list",
      title: "Categorie Parti",
      icon: "mdi mdi-cart",
      capacita: ["menu-parti"]
    },
    {
      module: "Spedizioni",
      path: "spedizioni/lista",
      title: "menu.SpedizioniLabel",
      icon: "mdi mdi-airplane",
      capacita: ["menu-spedizioni"]
    },
    {
      module: "Stampe",
      path: "stampe/templates",
      title: "menu.StampeLabel",
      icon: "mdi mdi-printer",
      class: "has-arrow",
      capacita: ["menu-stampa"],
      submenu: [
        {
          path: "stampe/templates",
          title: "Templates",
          capacita: ["gestione-stampa"]
        },
        {
          path: "stampe/marker",
          title: "Marker",
          capacita: ["menu-stampa"]
        }
      ]
    },
    {
      module: "Settings",
      path: "settings/settings",
      title: "menu.SettingsLabel",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Admin",
      path: "listautenti/roles",
      title: "Ruoli",
      icon: "mdi mdi-lock-open-outline",
      capacita: ["menu-ruoli"]
    },
    {
      module: "Ticket",
      path: "ticket/list",
      title: "Tickets",
      icon: "mdi mdi-bookmark-outline",
      capacita: ["menu-ticket"]
    },
    // {
    //   notruoliOperativi: ["CA","AC"],
    //   module: "Ecommerce",
    //   title: 'menu.EcommerceLabel',
    //   icon: 'mdi mdi-cart',
    //   class: 'has-arrow',
    //   submenu: [
    //     {
    //       path: '/ecom/cat',
    //       title: 'Products',
    //       icon: 'mdi mdi-cards-variant',
    //       class: '',
    //       label: '',
    //       labelClass: '',
    //       extralink: false,
    //       submenu: []
    //     },
    //     {
    //       path: '/ecom/cart',
    //       title: 'Cart',
    //       icon: 'mdi mdi-cart',
    //       class: '',
    //       label: '',
    //       labelClass: '',
    //       extralink: false,
    //       submenu: []
    //     },
    //     {
    //       path: '/ecom/orders',
    //       title: 'Orders',
    //       icon: 'mdi mdi-chart-pie',
    //       class: '',
    //       label: '',
    //       labelClass: '',
    //       extralink: false,
    //       submenu: []
    //     }
    //   ]
    // },
    {
      ruoliOperativi:["AC","C","CA"],
      path: 'Cliente/profile',
      title: 'menu.profilo',
      icon: "far fa-user"
    }
  ],
  //#endregion
};
